.header_back_cover{
  position: absolute;
  width: 100%;
  top: 0%;
}
.menu_bar_position ul li:hover ul{
  display: block;
  z-index: -3;
  margin-top: 3px;
}
.dropdown-item{
  border-bottom:  1px solid rgba(0, 0, 0, 0.116);

  
}
@media screen and (max-width:312px) {
  .dropdown-item{
    font-size: 5px;
  }
}
@media screen and (max-width:370px) {
  .dropdown-item{
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 991px) {
  .header_bg_color {
    background-color: none;
    height: auto;
  }
}
.top-header {
  display: flex;
  width: 100%;
  height: 20vh;
  background-color: #e4dc00;
}

.left-side {
  flex: 50%;
  background-image: url("../images/title_bg.png");
  background-size: cover;
}

.right-side {
  flex: 50%;
  background-color: aqua;
}

.nav_list {
  list-style: none;
  display: inline;
}

.social_logo {
  width: 2%;
  padding: 4px;
  margin: 5px 10px 5px 10px;
}

.title_bg_img {
  width: 100%;
  opacity: 1;
  z-index: -9;
}

.top_bar {
  position: absolute;
  right: 0;
  width: 100%;
  height: 5%;
  z-index: 1;
}

.navbar-item {
  text-align: left;
  font-family: "Montserrat", sans-serif;

  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  z-index: 1;
}

/* font: normal normal 600 18px/22px Montserrat; */
.nav_wholebar {
  z-index: 3;
}

.nav-item > a {
  text-decoration: none;
  color: white !important;
}

.nav-item > a:hover {
  color: black;
}

.wtcf_logo {
  position: absolute;
  margin-left: -1%;
  top: -7%;
  z-index: 2;
  width: 8.5%;
  padding: 5px 0px;
}

.website_title {
  color: white;
  position: absolute;
  padding-left: 8%;
  top: 13%;
  padding-top: 0.5%;
  font-family: "montserrat";
  letter-spacing: 10px;
  font-size: 3.2vw;
  z-index: 2;
  font-weight: 1000;
}

.website_tagline {
  color: white;
  position: absolute;
  top: 60%;
  padding-left: 7.5%;
  padding-top: 1%;
  font-size: 0.6vw;
  font-family: "montserrat";
  font-weight: bold;
  letter-spacing: 0.2vw;
}

.nav_bar_bg {
  position: absolute;
  top: 25%;
  width: 100%;
  height: 40%;
  z-index: -9;
  opacity: 1;
}

.menu_bar_home {
  justify-content: right;
}

.menu_bar_position {
  /* margin-top: -100%; */
  justify-content: right;
  /* background: #e4dc00; */
}

.dropdown-menu {
  background-color: #e4dc00;
  color: black;
  padding: 0;
  font-size: 0.8rem;
}

.dropdown-menu > a > p {
  font-family: "montserrat";
  font-weight: 600;
  margin: 0;
}

.social_links_topbar {
  text-align: right;
}

a.dropdown-toggle::after {
  display: none;
}

.nav-item > a.active {
  color: red;
}

.offcanvas-body {
  position: absolute;
  right: 5%;
  margin-top: -6.25%;
}

@media screen and (max-width: 6000px) {
  .offcanvas-body {
    position: absolute;
    right: 5%;
    margin-top: -6%;
  }
}

@media screen and (max-width: 5000px) {
  .offcanvas-body {
    position: absolute;
    right: 5%;
    margin-top: -6.09%;
  }
}

@media screen and (max-width: 2800px) {
  .offcanvas-body {
    position: absolute;
    right: 10%;
    margin-top: -6.1%;
  }
}

@media screen and (max-width: 2600px) {
  .offcanvas-body {
    position: absolute;
    right: 15%;
    margin-top: -6.05%;
  }
}

@media screen and (max-width: 2500px) {
  .offcanvas-body {
    position: absolute;
    right: 15%;
    margin-top: -6.1%;
  }
}

@media screen and (max-width: 2200px) {
  .offcanvas-body {
    position: absolute;
    right: 12%;
    margin-top: -6.2%;
  }
}

@media screen and (max-width: 1900px) {
  .offcanvas-body {
    position: absolute;
    right: 12%;
    margin-top: -6.15%;
  }
}

@media screen and (min-width: 1800px) {
  .offcanvas-body {
    position: absolute;
    right: 13%;
    margin-top: -6.2%;
  }
}

@media screen and (max-width: 1700px) {
  .offcanvas-body {
    position: absolute;
    right: 5%;
    margin-top: -6.15%;
  }
}

@media screen and (max-width: 1600px) {
  .offcanvas-body {
    position: absolute;
    right: 5%;
    margin-top: -6.19%;
  }
}

@media screen and (max-width: 1400px) {
  .offcanvas-body {
    position: absolute;
    right: 5%;
    margin-top: -6.25%;
  }
}

@media screen and (max-width: 1300px) {
  .offcanvas-body {
    position: absolute;
    right: 5%;
    margin-top: -6.4%;
  }
}

.navbar-toggler {
  position: absolute;
  right: 6%;
  margin-top: 9%;
  /* margin-top: -3%;
  margin-right: 3%; */
  width: 8vw;
  padding: 5px;
  border-radius: 5px;
  font-size: 3vw;
  border-color: rgb(255, 102, 203);
  font-size: 20px;
}

.offcanvas {
  background-color: #000000;
}

@media screen and (max-width: 991px) {
  .offcanvas-body {
    position: absolute;
    left: 15%;
    margin-top: 10%;
  }

  .social_logo {
    width: 4%;
    padding: 2px;
    margin: -10px 10px 3px 10px;
  }

  .dropdown-menu {
    background-color: #e4dc00;
    color: black;
    padding: 0;
    /* font-size: 14px; */
  }
}

@media only screen and (max-width: 356px) {
  .title_bg_img {
    width: 100%;
    opacity: 1;
    z-index: -9;
    margin-top: -10px;
  }

  .website_tagline {
    color: white;
    position: absolute;
    top: 50%;
    padding-left: 7.5%;
    font-size: 0.6vw;
    font-family: "montserrat";
    font-weight: bold;
    letter-spacing: 0.2vw;
  }
}

@media (min-width: 2200px) and (max-width: 6000px) {
  .website_tagline {
    /* display:none!important; */
    position: fixed;
    top: 10%;
    left: 10%;
    z-index: 1;
  }
  .website_title {
    position: fixed;
    top: 1%;
    left: 9%;
    z-index: 1;
  }
  .wtcf_logo {
    position: fixed;
    top: -1%;
    left: 9%;
    z-index: 1;
  }
}

@media (min-width: 1600px) and (max-width: 2199px) {
  .website_tagline {
    /* display:none!important; */
    position: fixed;
    top: 10%;
    left: 10%;
    z-index: 1;
  }
  .website_title {
    position: fixed;
    top: 1%;
    left: 9%;
    z-index: 1;
  }
  .wtcf_logo {
    position: fixed;
    top: -1%;
    left: 9%;
    z-index: 1;
  }
}

.dropdown-menu {
  border-radius: 0%;
}

@media screen and (max-width: 991px) and (min-width: 764px) {
  .wtcf_logo {
    width: 12%;
    margin-top: -1.5%;
  }

  .website_title {
    padding-top: 2%;
    padding-left: 20%;
    font-size: 35px;
  }

  .website_tagline {
    padding-left: 20%;
    padding-top: 7%;
    font-size: 10px;
  }

  .nav_bar_bg {
    height: 550%;
    top: 0%;
  }
}

@media screen and (max-width: 763px) and (min-width: 421px) {
  .wtcf_logo {
    width: 16%;
    margin-top: -1%;
  }

  .website_title {
    padding-top: 1%;
    padding-left: 20%;
    font-size: 35px;
  }

  .website_tagline {
    padding-left: 20%;
    padding-top: 9.5%;
    font-size: 8px;
  }

  .nav_bar_bg {
    height: 500%;
    top: 0%;
  }
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

@media screen and (max-width: 420px) and (min-width: 200px) {
  .wtcf_logo {
    width: 17.5%;
    margin-top: -1.4%;
  }

  .website_title {
    padding-top: 1%;
    padding-left: 20%;
    font-size: 1.3rem;
  }

  .website_tagline {
    padding-left: 20%;
    padding-top: 2rem;
    font-size: 0.455rem;
  }

  .nav_bar_bg {
    height: 400%;
    top: 0;
  }
}

@media (min-width: 1800px) {
  .dropdown-item {
    font-size: 18px;
  }
}
@media (min-width: 2200px) {
  .dropdown-item {
    font-size: 25px;
  }
}
@media (min-width: 3800px) {
  .dropdown-item {
    font-size: 40px;
  }
}
