.mem_countries_flags {
    border-top: 4px solid #E4DC00;
    background: url("../images/mem_countries_bg.png");
}

.member_countries_title {
    margin-bottom: 0px;
    font-family: 'Cormorant', sans-serif;
    word-spacing: 10px;
  font-size: 1.6rem;
    
}

.countries_flag {
    display: flex;
    text-align: center;
    justify-content: center;
}

.countries_flags_align {
    width: 150px;
    row-gap: 50px;
}

.countries_flags_align>p{
    text-align: center;
}

@media screen and (max-width:400px){
 .member_countries_title{
    font-size: 1.1rem;
 }
}