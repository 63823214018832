.super_league_text {
    padding-left: 8%;
    padding-right:7%;
    margin-bottom: 0%;
    font-size:1.5rem;
}

.super_league {
    height: 100%;
    width: 100%;
}

.super_league_image {
    filter: brightness(50%);
    width: 100%;
    height: 100%;
}

.super_league_content {
    margin-top: -12px;
}

.super_league_border {
    border-bottom: 0px solid #E4DC00;
    margin-top: -5%;
    position: relative;
}

.super_border {
    border-bottom: 4px solid #E4DC00;
    margin-top: -3%;
    position: relative;
}

@media screen and (max-width:6000px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -0.75%;
        position: relative;
    }
}

@media screen and (max-width:5000px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -1%;
        position: relative;
    }
}

@media screen and (max-width:3000px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -1.5%;
        position: relative;
    }
}

@media screen and (max-width:2200px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -2%;
        position: relative;
    }
}

@media screen and (max-width:1850px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -2.35%;
        position: relative;
    }
}

@media screen and (max-width:1550px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -2.7%;
        position: relative;
    }
}

@media screen and (max-width:1450px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -3.1%;
        position: relative;
    }
}

@media screen and (max-width:1300px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -3.5%;
        position: relative;
    }
}

@media screen and (max-width:1100px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -3.9%;
        position: relative;
    }
}

@media screen and (max-width:1000px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -4.1%;
        position: relative;
    }
}

@media screen and (max-width:900px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -4.8%;
        position: relative;
    }
}

@media screen and (max-width:690px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -5.5%;
        position: relative;
    }
}

@media screen and (max-width:600px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -4.3%;
        position: relative;
    }
}

@media screen and (max-width:460px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -5.2%;
        position: relative;
    }
}

@media screen and (max-width:350px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -7%;
        position: relative;
    }
}

@media screen and (max-width:280px) {
    .super_border {
        border-bottom: 4px solid #E4DC00;
        margin-top: -8.5%;
        position: relative;
    }
}