.outer_container{
   margin-top: 15%;
}
.advisor_title_div{
   background-image: url('../../images/rules_headings.png');
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat; 
  margin-bottom: -1rem;
}
.board_outer_div{
  background-color: #1937A1;
  border-top: 5px solid #E4DC00;
  padding:1%;
  margin: 0px;
}
.board_inner_div{
    /* background-color: aqua; */
    display: grid;
   padding: 4% 1%;
    justify-items: center;
    grid-gap: 20px;
}
.board_card_div{
    height: 50vh;
    width: 60vw;
    background-color: gray;
    border-radius: 5px;
}
.board_card_div img{
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

@media screen and (max-width:395px) {
  .advisor_title_div h1{
    font-size: 1em;
  }
}
@media only screen and (min-width:630px) and (max-width:760px){
  .advisor_title_div h1{
    font-size: 22px;
  }
}
@media only screen and (min-width:792px) and (max-width:944px){
  .advisor_title_div h1{
    font-size: 25px;
  }
}
@media screen and (min-width:321px) {
    .board_inner_div{
      grid-template-columns: repeat(2,1fr);
      justify-content: center;
    }
    .board_card_div{
      height: 170px;
      width: 135px;
    }
  }
  @media screen and (min-width:450px) {
    
    .board_inner_div{
      grid-template-columns: repeat(3,1fr);
      justify-content: center;
    }
    .board_card_div{
      height: 170px;
      width: 120px;
    }
  }
  @media screen and (min-width:580px) {
    
    .board_card_div{
      height: 200px;
      width: 150px;
    }
  }
  @media only screen and (min-width:602px) and (max-width:720px) {
    
  .board_card_div{
      height: 200px;
      width: 150px;
    }
  }
  @media only screen and (min-width:768px) {
    .board_inner_div{
      grid-template-columns: repeat(4,1fr);
      justify-content: center;
    }
    .board_card_div{
        height: 200px;
        width: 150px;
      }
    }
  @media only screen and (min-width:992px) {
      .board_inner_div{
        grid-template-columns: repeat(4,1fr);
        justify-content: center;
      }
      .board_card_div{
          height: 250px;
          width: 200px;
        }
  }
  @media only screen and (min-width:1400px) {
      .board_inner_div{
        grid-template-columns: repeat(5,1fr);
      }
      .board_card_div{
          height: 250px;
          width: 200px;
        }
  }