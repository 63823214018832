body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #191c1e;
}
.heading_bg {
  border-bottom: 34px solid #e4dc00 !important;
  border-right: 25px solid transparent;
  height: 0;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 1rem;
}
.heading_bg_sl {
  border-bottom: 37px solid #e4dc00 !important;
  border-right: 50px solid transparent;
  height: 0;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 1.5rem;
}
.heading_bg_sl span{
  position: relative;
  top: 7px;
}
.heading_bg_mem {
  border-bottom: 32px solid #e4dc00 !important;
  border-right: 25px solid transparent;
  height: 0;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 1rem;
  display: flex;
  align-items: baseline;
}
.heading_bg_wc_news {
  border-bottom: 39px solid #e4dc00 !important;
  border-right: 25px solid transparent;
  height: 0;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  align-items: baseline;
}
.heading_bg_wc_news span{
  font-size: 1.5rem;
  position: relative;
  left: 15%;
}
.heading_bg_faq {
  border-bottom: 33px solid #e4dc00 !important;
  border-right: 25px solid transparent;
  height: 0;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 1rem;
  text-align: center;
  display: flex;
  align-items: baseline;
}
.heading_bg_faq_wc {
  border-bottom: 30px solid #e4dc00 !important;
  border-right: 25px solid transparent;
  height: 0;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
 
}
.heading_bg_faq_wc span{
  font-size: 24px;
}
.heading_bg_news {
  border-bottom: 40px solid #e4dc00 !important;
  border-right: 25px solid transparent;
  height: 0;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
}
.heading_bg1 {
  border-bottom: 23px solid #e4dc00 !important;
  border-right: 30px solid transparent;
  height: 0;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
  font-family: "Cormorant", sans-serif;
  font-size: 1.6rem;
}
.heading_bg_wc_title {
  border-bottom: 37px solid #e4dc00 !important;
  border-right: 50px solid transparent !important;
  height: 0;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
  font-family: 'Cormorant', sans-serif;
  font-size: 1.5rem;
  /* border-top: 10px solid yellow; */
  /* border-left: 10px solid transparent; */
}
.heading_bg_wc_title span{
  /* border-top: 10px solid yellow; */
  position: relative;
  top: 7px;
  
}

.heading_bg_pp {
  border-bottom: 23px solid #e4dc00 !important;
  border-right: 30px solid transparent;
  height: 0;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
  font-family: "Cormorant", sans-serif;
  font-size: 1.6rem;
}
.heading_bg_tc {
  border-bottom: 23px solid #e4dc00 !important;
  border-right: 30px solid transparent;
  height: 0;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
  font-family: "Cormorant", sans-serif;
  font-size: 1.6rem;
}
@media screen and (max-width: 991px) {
  .heading_bg {
    border-bottom: 42px solid #e4dc00 !important;
    border-right: 25px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 1rem;
  }
  .heading_bg_mem {
    border-bottom: 24px solid #e4dc00 !important;
    border-right: 25px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 1rem;
  }
  .heading_bg_wc_news {
    border-bottom: 37px solid #e4dc00 !important;
    border-right: 25px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 20px;
    padding-left: 20px;
  }
  .heading_bg_faq {
    border-bottom: 40px solid #e4dc00 !important;
    border-right: 30px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 1rem;
  }
  .heading_bg_faq_wc {
    border-bottom: 35px solid #e4dc00 !important;
    border-right: 25px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 1.6rem;
  }
  
  .heading_bg_news {
    border-bottom: 42px solid #e4dc00 !important;
    border-right: 25px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 990px) {
  .heading_bg1 {
    border-bottom: 32px solid #e4dc00 !important;
    border-right: 35px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: -5px;
    font-size: 1rem;
  }
  .heading_bg_pp {
    border-bottom: 16px solid #e4dc00 !important;
    border-right: 35px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: -5px;
    font-size: 1rem;
  }
  .heading_bg_tc {
    border-bottom: 20px solid #e4dc00 !important;
    border-right: 35px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: -5px;
    font-size: 1rem;
  }
  .heading_bg_me {
    border-bottom: 24px solid #e4dc00 !important;
    border-right: 25px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: -5px;
    font-size: 1.2rem;
  }
}
/* To hide scrollbar  */
body {
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* To hide scrollbar from firefox */
html {
  scrollbar-width: none;
}

/* background-color: black; */

* {
  box-sizing: border-box;
}

h2 {
  font-family: "Cormorant", sans-serif;
  font-style: normal;
  word-spacing: 5px;
  font-weight: bolder;
}
h1 {
  font-family: "Cormorant", sans-serif;
  font-style: normal;
  word-spacing: 5px;
  font-weight: bolder !important;
}
/*  font-size: 4vw; 
    word-spacing: 0.5vw;
    letter-spacing:0.1vw;*/
code {
  font-family: "Montserrat";
}
p {
  font-family: "Montserrat";
  text-align: justify;
  text-justify: inter-word;
}
.title {
  font-family: "montserrat";
}
.title::after {
  color: white;
}
.accordion-item {
  border: 0px;
}

/* .sticky_navbar{
  position: sticky;
  top:0%;
} */

@media screen and (max-width: 991px) {
  .top_image_view {
    margin-top: 12%;
  }
  .heading_bg_sl span{
    font-size: 15px;
  }
  .heading_bg_wc_title span{
   font-size: 16px; 
  }
}
