.privacy_policy_text {
    font-size: 1rem;
}

.nct_contact {
    font-size: 0.7rem;
    text-decoration: none !important;
}

.social_icon {
    background: #461772;
    width: 40px;
    height: 40px;
    padding: 10px 10px;
    border-radius: 50%;
}

.social_icon:hover {
    background: #4CE50E;
}

/* .social_icon1 {
    background: #461772;
    width: 40px;
    height: 40px;
    padding: 12px 10px;
    font-size: 17px;
    border-radius: 35px;
    text-align: center;
}

.fb_icon:hover {
    color: #4CE50E;
} */
.contact_links a{
    text-decoration: none !important;
}
@media screen and (min-width:768px) and (max-width:875px) {
    .follow_us_text {
        margin-left: -10px;
    }

    .social_icon {
        margin-left: -10px;
    }
}

@media screen and (min-width:300px) and (max-width:325px) {
    .social_icon {
        width: 35px;
        height: 35px;
        padding: 10px 10px;
    }
}

@media screen and (min-width:250px) and (max-width:300px) {
    .social_icon {
        width: 25px;
        height: 25px;
        padding: 7px 7px;
        margin-right: -0.5vw;
    }
}

@media screen and (max-width:250px) {
    .social_icon {
        width: 20px;
        height: 20px;
        padding: 5px 5px;
        margin-right: -8px;
    }
}