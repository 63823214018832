.item {
    /* margin-bottom: 5px; */
    padding: 10px 20px;
}

.item:nth-child(even) {
    background: #002463 !important;
    color: white !important;
}

.item:nth-child(odd) {
    background: #1937A1 !important;
    color: white;
}

.title {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 10px;
}

.content {
    color: white;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.accordion_outer_div {
    border-top: 5px solid #E4DC00;
    margin-top: -0.8%;
}

.plus_button {
    font-size: 30px;
}
.faq_question{
    font-size: 18px;
  }
  .faq_answer{
     line-height: 1.3;
    font-size: 16px;
  }



@media screen and (max-width:360px) {
    .faq_section v {
        margin-top: 20px;
    }
}

.view_more_btn {
    background: #E83F0C;
    width: 20%;
    border-radius: 0%;
}

@media screen and (min-width:128px) and (max-width:209px) {
    .view_more_btn {
        font-size: 10px;
        width: 100%;
    }
}

.view_more_btn>a {
    text-decoration: none;
    color: white;
}

@media screen and (min-width:210px) and (max-width:360px) {
    .view_more_btn {
        font-size: 10px;
        width: 60%;
    }

    .accordion_outer_div {
        margin-top: -8%;
    }
}

@media screen and (min-width:361px) and (max-width:475px) {
    .view_more_btn {
        font-size: 10px;
        width: 50%;
    }

    .accordion_outer_div {
        margin-top: -8%;
    }
}

@media screen and (min-width:476px) and (max-width:800px) {
    .view_more_btn {
        font-size: 12px;
        width: 30%;
    }
}

@media screen and (min-width:360px) and (max-width:991px) {
    .accordion_outer_div {
        margin-top: -3%;
    }
}
@media screen and (min-width:991px) and (max-width:1190px){
    .accordion_outer_div {
        margin-top: -1.3%;
    }
}