.header {
  background-image: url("../images/NCT-BgImage.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.nct_wtcf_logo {
  height: 100px;
  width: 105px;
  margin-left: 0%;
}

.Nct_heading_text {
  font-size: 1.5rem;
  width: 20%;
}

.wtcf_tour_banner {
  margin-top: 5%;
  height: 45vh;
  width: 40vw;
  padding-bottom: 10px;
}

.nct_download_column {
  background-color: #5A189A;
  position: relative;
  right: 15%;
  height: 35vh;
  margin-top: 5%;
  /* z-index: 1; */
}

.nct_text {
  position: absolute;
  font-size: 2.5rem;
  font-weight: 800;
  top: 17%;
  z-index: 1;
  font-family: Helvetica !important;
}

.download_text {
  position: absolute;
  bottom: 10%;
  color: #4CE50E;
  font-size: 1.2vw;
  font-weight: 600;
}

.Nct_Downlaod_Icon {
  position: absolute;
  height: 5vh;
  width: 2.5vw;
  bottom: 15%;
  left: 25vw;
}

.tournament_btn {
  background-color: #5D2C8B;
  border-radius: 27px;
  width: fit-content;
  padding: 13px 30px;
  margin-right: 30px;
  margin-bottom: 40px;
}

.rewards_btn {
  background-color: #5D2C8B;
  width: fit-content;
  border-radius: 27px;
  padding: 13px 30px;
  margin-right: 30px;
  margin-bottom: 40px;
}

.rules_btn {
  background-color: #5D2C8B;
  width: fit-content;
  margin-right: 30px;
  border-radius: 27px;
  padding: 13px 30px;
  margin-bottom: 40px;
}

/* sticky header */
.header1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.header2 {
  position: fixed;
  top: 0;
  left: 45%;
  padding-top: 20px;
  width: 100%;
  height: 0%;
  background-color: #5D2C8B;
}

.header2 .rules_btn {
  background-color: #310659;
  width: fit-content;
  margin-right: 30px;
  border-radius: 27px;
  padding: 13px 30px;
  margin-bottom: 40px;
  font-size: 13px;
}

.header2 .rewards_btn {
  background-color: #310659;
  width: fit-content;
  margin-right: 30px;
  border-radius: 27px;
  padding: 13px 30px;
  margin-bottom: 40px;
  font-size: 13px;
}

.header2 .tournament_btn {
  background-color: #310659;
  width: fit-content;
  margin-right: 30px;
  border-radius: 27px;
  padding: 13px 30px;
  margin-bottom: 40px;
  font-size: 13px;
}

.wtcf_header2 {
  top: 0;
  position: fixed;
  background-color: #5D2C8B;
  width: 100%;
  height: max-content;
  left: 0;
}


/* side menu for small device */

.nav-bar {
  height: 80px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.menu-bar {
  margin-right: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background: #5D2C8B;
  width: 250px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0%;
  right: -100%;
  transition: 1.5s;

}

.nav-menu.active {
  right: 0px;
  transition: 1s
}

.nav_text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 15px;
  list-style: none;
  height: 60px;
}

.nav_text a {
  text-decoration: none;
  color: white;
  font-size: 15px;
  width: 100%;
  height: 1005;
  display: flex;
  align-items: center;
}

.nav_text:hover {
  color: yellow;
  background-color: #4CE50E;

}

.nav_text:active {
  color: yellow;
  background-color: #4CE50E;
}

/* sidemenu for small device */
@media screen and (max-width:325px) {
  .button_div {
    display: none;
  }

  .toggle2 {
    top: -20px;
    position: fixed;
    left: 83%;
    z-index: +2;
  }

  .nct_toggler_icon {
    margin-top: 20px;
    height: 35px;
    width: 35px;
  }

  .nct_wtcf_logo {
    height: 60px;
    width: 60px;
  }

  .Nct_heading_text {
    font-size: 0.5rem;
  }

  .wtcf_tour_banner {
    height: 90%;
    width: 100%;
  }

  .nct_download_column {
    right: 0%;
    left: 7%;
    height: 100px;
    margin-top: -15%;
    width: 86%;
    margin-bottom: 5%;
  }

  .nct_text {
    font-size: 0.8rem;
    font-family: Helvetica !important;
  }

  .Nct_Downlaod_Icon {
    height: 20px;
    width: 20px;
    left: 61vw;
    margin-bottom: -1%;
  }

  .download_text {
    bottom: 0%;
    font-size: 2.5vw;
  }

  .nav-bar {
    height: 80px;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .menu-bar {
    margin-right: 2rem;
    font-size: 2rem;
    background: none;
  }

  .nav-menu {
    background: #5D2C8B;
    width: 200px;
    height: 100%;
    top: 0%;
    right: -100%;
  }
}

@media screen and (min-width:325px) and (max-width:376px) {
  .button_div {
    display: none;
  }

  .toggle2 {
    top: -20px;
    position: fixed;
    left: 87%;
    z-index: +2;
  }

  .nct_toggler_icon {
    margin-top: 20px;
    height: 35px;
    width: 35px;
  }

  .nct_wtcf_logo {
    height: 60px;
    width: 60px;
  }

  .Nct_heading_text {
    font-size: 0.8rem;
  }

  .wtcf_tour_banner {
    height: 90%;
    width: 100%;
  }

  .nct_download_column {
    right: 0%;
    left: 7%;
    height: 120px;
    margin-top: -15%;
    width: 86%;
    margin-bottom: 5%;
  }

  .nct_text {
    font-size: 1.1rem;
    font-family: Helvetica !important;
  }

  .Nct_Downlaod_Icon {
    height: 20px;
    width: 20px;
    left: 56vw;
    margin-bottom: -1%;
  }

  .download_text {
    bottom: 0%;
    font-size: 2.5vw;
  }
}

@media screen and (min-width:376px) and (max-width:482px) {
  .button_div {
    display: none;
  }

  .toggle2 {
    top: -13px;
    position: fixed;
    left: 87%;
    z-index: +2;
  }

  .nct_wtcf_logo {
    height: 60px;
    width: 60px;
  }

  .Nct_heading_text {
    font-size: 1rem;
  }

  .wtcf_tour_banner {
    height: 90%;
    width: 100%;
  }

  .nct_download_column {
    right: 0%;
    left: 5%;
    height: 150px;
    margin-top: -15%;
    width: 90%;
    margin-bottom: 5%;
  }

  .nct_text {
    font-size: 1.5rem;
    font-family: Helvetica !important;
  }

  .Nct_Downlaod_Icon {
    height: 30px;
    width: 30px;
    left: 56vw;
    bottom: 10%;
    margin-bottom: 0%;
  }

  .download_text {
    bottom: 2%;
    font-size: 2.5vw;
  }
}

@media only screen and (min-width:482px) and (max-width:768px) {
  .button_div {
    display: none;
  }

  .toggle2 {
    top: -15px;
    position: fixed;
    left: 90%;
    z-index: +2;
  }

  .nct_wtcf_logo {
    height: 60px;
    width: 60px;
  }

  .Nct_heading_text {
    font-size: 1rem;
  }

  .wtcf_tour_banner {
    height: 90%;
    width: 100%;
  }

  .nct_download_column {
    right: 0%;
    left: 5%;
    height: 200px;
    margin-top: -15%;
    width: 90%;
    margin-bottom: 5%;
  }

  .nct_text {
    font-size: 2rem;
    font-family: Helvetica !important;
  }

  .Nct_Downlaod_Icon {
    height: 30px;
    width: 30px;
    left: 53vw;
    bottom: 16%;
    margin-bottom: 0.5%;
  }

  .download_text {
    font-size: 2.5vw;
  }
}

@media only screen and (min-width:768px) {

  .toggle2 {
    display: none;
  }

  .nct_wtcf_logo {
    height: 60px;
    width: 60px;
  }

  .Nct_heading_text {
    font-size: 1rem;
  }

  .wtcf_tour_banner {
    height: 90%;
    width: 100%;
  }

  .nct_download_column {
    right: 20%;
    height: 130px;
    margin-top: 4.5%;
  }

  .nct_text {
    font-size: 1.5rem;
    font-family: Helvetica !important;
  }

  .Nct_Downlaod_Icon {
    height: 30px;
    width: 30px;
    left: 60%;
    bottom: 9%;
  }

  .download_text {
    font-size: 0.6rem;
    margin-bottom: 1%;
  }

  .tournament_btn {
    padding: 10px 13px;
  }

  .rewards_btn {
    padding: 10px 13px;
  }

  .rules_btn {
    padding: 10px 13px;
  }

  .privacy_policy_text {
    margin-top: 20px;
  }

  /* sticky header */
  .header2 {
    position: fixed;
    top: 0;
    left: 44%;
    padding-top: 13px;
    width: 100%;
    height: 0%;
    background-color: #5D2C8B;
  }

  .header2 .rules_btn {
    margin-right: 10px;
    border-radius: 27px;
    padding: 7px 9px;
    font-size: 10px;
  }

  .header2 .rewards_btn {

    margin-right: 10px;
    border-radius: 27px;
    padding: 7px 9px;
    font-size: 10px;
  }

  .header2 .tournament_btn {

    margin-right: 10px;
    border-radius: 27px;
    padding: 7px 9px;
    font-size: 10px;
  }

  .wtcf_header2 {
    top: 0;
    position: fixed;
    background-color: #5D2C8B;
    width: 100%;
    height: max-content;
    left: 0;
  }

}

@media only screen and (min-width:992px) {
  .Nct_heading_text {
    font-size: 1.2rem;
    width: 20%;
  }

  .wtcf_tour_banner {
    height: 90%;
    width: 100%;
  }

  .nct_download_column {
    height: 170px;
    right: 15%;
    margin-top: 5%;
  }

  .nct_text {
    font-size: 2rem;
    font-family: Helvetica !important;
  }

  .Nct_Downlaod_Icon {
    height: 30px;
    width: 30px;
    left: 45%;
    /* bottom: 9%; */
  }

  .download_text {
    font-size: 0.6rem;
    margin-bottom: 1%;
  }

  /* sticky header */
  .header2 {
    padding-top: 10.5px;
    left: 45%;
  }

  .header2 .rules_btn {
    padding: 10px 25px;
    font-size: 10px;
  }

  .header2 .rewards_btn {
    padding: 10px 25px;
    font-size: 10px;
  }

  .header2 .tournament_btn {
    padding: 10px 25px;
    font-size: 10px;
  }
}

@media only screen and (min-width:1200px) {
  .nct_download_column {
    height: 190px;
    margin-top: 6%;
  }

  .nct_text {
    font-size: 2.5rem;
    font-family: Helvetica !important;
  }

  .download_text {
    font-size: 0.8rem;
    margin-bottom: 1%;
  }

  .Nct_Downlaod_Icon {
    height: 30px;
    width: 30px;
    left: 50%;
    bottom: 11%;
  }

  .header2 {
    padding-top: 10.5px;
    left: 54%;
  }

}

@media only screen and (min-width:1400px) {
  .nct_download_column {
    height: 230px;
    margin-top: 6%;
  }

  .nct_text {
    font-size: 2.7rem;
  }

  .download_text {
    margin-bottom: 3%;
  }

  .Nct_Downlaod_Icon {
    bottom: 17%;
    left: 43%;
  }

  /* sticky header */
  .header2 {
    padding-top: 8px;
  }

  .header2 .rules_btn {
    margin-right: 30px;
    padding: 10px 25px;
    margin-bottom: 40px;
    font-size: 13px;
  }

  .header2 .rewards_btn {

    margin-right: 30px;
    padding: 10px 25px;
    margin-bottom: 40px;
    font-size: 13px;
  }

  .header2 .tournament_btn {
    margin-right: 30px;
    padding: 10px 25px;
    margin-bottom: 40px;
    font-size: 13px;
  }
}

@media screen and (min-width:1537px) {
  .header2 {
    left: 51%;

  }
}