#priv_policy_title {
    font-family: 'Cormorant', sans-serif;
    border-bottom: 4px solid #E4DC00;
}

#priv_policy_posi {
    padding-top: 12%;
}

.privacy_content {
    border-top: 4px solid #E4DC00;
    margin-top: -16px;
}

#text_gab_policy {
    margin-top: 30px;
}