
.news_title_div{
    color: rgb(0, 0, 0);
    margin-bottom: -20px;
    padding-top: 5px;
}
.news_title_text{
    font-family: 'Cormorant', sans-serif;
    font-style: normal;
    word-spacing: 10px;
    padding-left: 10px;
    font-size: 1.6rem;
    font-weight: 800;
    width: 200px;
    text-align: center;
}

.newT_description{
    font-size: 0.9rem;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
            line-clamp: 2; 
    -webkit-box-orient: vertical;
    /* background-color: #E4DC00; */
}

    .slick-slide > div{
    margin: 0 10px;
    }
    .slick-list{
        margin: 0 -10px;
    }
    .outer-test{
        background-color: rgb(2, 2, 2);
    }
    .inner-test{
        background-color: rgb(180, 180, 180);
        color:white;
        padding: 15px;
    }


.news_child_div{
   background-color: #1937A1;
   border-top: 5px solid #E4DC00;
   padding:3% 4%;
}
.inner_news_div{
    background-color: white;
    border-radius: 5px;
    margin-left: 0px;
    border: 0px;
}
.inner_news_div .image{
    height: auto;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 3px solid red;
}
.newT_topic{
    margin-top: 8px;
    margin-bottom: 5px;
     font-size: .7vw; 
    /* font-size: 9px; */
    margin-left: 1vw;
    margin-right: 1vw;
    color: rgb(172, 172, 172);
    font-weight: 600;
    letter-spacing: 1px;
}
.newT_description{
    margin-bottom: 5x;
     font-size: 1vw; 
    /* font-size: 13px; */
    margin-left: 1vw;
    margin-right: 1vw;
}

.newsT_date,.know_more_btn{
    font-size: 1vw;

}
/* @media screen and (max-width:800px){
    .inner_news_div{
     
        height: 60vh;
    }
} */
@media screen and (max-width:400px) {
    .newT_topic,.card-bottom .date{
        font-size: 7.7px;
    }
    .newT_description{
        font-size: 12px;
        font-weight: 700;
    }
}
@media   screen and (min-width:401px) and (max-width:600px){
    .newT_topic,.newsT_date,.know_more_btn{
        font-size: 10px;
    }
    .newT_description{
        font-size: 10px;
    }
}
@media   screen and (min-width:200px) and (max-width:400px){
    .know_more_btn,.newsT_date{
        font-size: 9px;
    }
}
@media   screen and (min-width:900px) and (max-width:1800px){
    .know_more_btn,.newsT_date{
        font-size: 12px;
    }
}
@media only screen and (min-width:601px) and (max-width:767px){
    .newT_topic,.newsT_date,.card-bottom .know_more_btn{
        font-size: 10px;
    }
    .newT_description{
        font-size: 12px;
    }
}
@media only screen and (min-width:768px) and (max-width:800px){
    .newT_topic,.newsT_date,.card-bottom .know_more_btn{
        font-size: 10px;
    }
    .newT_description{
        font-size: 12px;
    }
}
@media screen and (min-width:3000px) {
    .newT_topic,.newsT_date,.card-bottom .know_more_btn{
        font-size: 30px;
    }
    .card-bottom .newT_description{
        font-size: 35px;
    }
}
@media screen and (min-width:3000px) {
.newT_topic{
    width:400px;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
}
@media screen and  (min-width:801px) and (max-width:991px) {
.newT_topic{
    width:250px;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
}



