.faq_image {
  width: 100%;
  height: 100%;
  filter: brightness(35%);
  background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgb(70, 70, 70));
}

.faq_image_gradient {
  position: absolute;
  top: 0%;
  margin-top: 0px;
  z-index: 2;
  width: 100%;
  height: calc(80% + 40px)
}

.item {
  padding: 10px 20px;
}

.item:nth-child(even) {
  background: #002463 !important;
  color: white !important;
}

.item:nth-child(odd) {
  background: #1937A1 !important;
  color: white;
}

.title {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 10px;
}

.content {
  color: white;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.accord_outer_div {
  border-top: 5px solid #E4DC00;
  margin-top: -0.7%;
}

.plus_button {
  font-size: 30px;
}

.faq_question {
  font-size: 18px;
}

.faq_answer {
   line-height: 1.3;
  font-size: 16px;
}