 .wtcf_gallery_content_head {
     position: absolute;
     margin-top: 12%;
     padding-left: 10%;
 }

 .arrow_action {
     float: right;
     padding: 5px;
 }

 .arrow_left {
     background-color: white;
     margin-right: 5px;
 }

 .arrow_right {
     background-color: white;
 }

 #wtcf_world_cup_contents {
     font-size: 1.5vw;
 }

 #wtcf_world_cup_head {
     font-size: 3vw;
 }

 #wtcf_world_cup_contents{
    filter: brightness(40%);
 }

 /* .etbmwA {
    position: absolute;
    top: -12%;
    right: 10%;
}  */


 /* .carousel-control .icon-prev, .carousel-control .icon-next{
    display: inline-block;
    position: absolute !important;
    top: 50%;
    z-index: 5;
    background-color: aqua;
} */
/* .sc-bZQynM,.bMcwED{
    padding:0px 150px 0px 150px;
}
@media screen and (max-width:991px) {
    .sc-bZQynM,.bMcwED{
        padding:0px 0px 0px 0px;
    }
 } 
@media screen and (min-width:992px) and (max-width:1200px){
    .sc-bZQynM,.bMcwED{
        padding:0px 20px 0px 20px;
    }
 }  */