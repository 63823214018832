.events_sig_border {
    border-bottom: 4px solid #E4DC00;

}

.carousel_img_phone {
    width: 100%;
}

.event_phone_clr {
    background-color: #1937A1;
}
.heading_bg1{
    font-weight: bold!important;
    font-size :0.8rem;
}

@media screen and (min-width:992px) {
    .mob_event_sign {
        display: none;
    }
}