.world_cup_image {
    height: 100%;
    width: 100%;
    filter: brightness(50%);
}

.world_cup_text {
    /* background-image: url("../images/rules_headings.png");
    background-repeat: no-repeat;
    background-size: cover; */
    padding-left: 8%;
    padding-right:7%;
    margin-bottom:0%;
    font-size: 1.5rem;
}
/* .world_cup_text span{
margin-bottom: -100px!important;

} */

#world_cup_container{
    /* border-top:4px solid #E4DC00; */
    margin-top: -12px;
    padding-right:10px;
}
.world_cup_border{
    border-bottom:4px solid #E4DC00;
    margin-top: -4%;
    position: relative;
}
@media screen and (max-width:6000px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -0.7%;
        position: relative;
    }
}
@media screen and (max-width:5000px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -0.9%;
        position: relative;
    }
}
@media screen and (max-width:3000px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -1.33%;
        position: relative;
    }
}
@media screen and (max-width:2200px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -1.866%;
        position: relative;
    }
}
@media screen and (max-width:1850px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -2%;
        position: relative;
    }
}
@media screen and (max-width:1550px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -2.5%;
        position: relative;
    }
}
@media screen and (max-width:1450px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -3%;
        position: relative;
    }
}
@media screen and (max-width:1300px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -3.5%;
        position: relative;
    }
}
@media screen and (max-width:1100px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -3.9%;
        position: relative;
    }
}
@media screen and (max-width:1000px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -4.4%;
        position: relative;
    }
}
@media screen and (max-width:900px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -4.9%;
        position: relative;
    }
}
@media screen and (max-width:690px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -5.4%;
        position: relative;
    }
}
@media screen and (max-width:600px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -6.7%;
        position: relative;
    }
}
@media screen and (max-width:460px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -8.1%;
        position: relative;
    }
}
@media screen and (max-width:350px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -12.2%;
        position: relative;
    }
}
@media screen and (max-width:280px) {
    .world_cup_border{
        border-bottom:4px solid #E4DC00;
        margin-top: -15%;
        position: relative;
    }
}