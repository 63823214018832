
.navbar-nav {
	list-style: none;
	padding: 0;
	margin:0;
	width:100%;
	background-size:cover;
	.contact-item{
		float: right;
		font-weight: bold;
		color: #fff;
		padding: 5px 20px 5px 20px;
		margin-right:0px!important;
		position: relative;
		cursor: pointer;
		
		&:after {
			content: "";
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;	
			z-index: -1;
			transform: skew(-30deg);
			transition: all .2s linear;
			color:white;
			background:#E4DC00;
			height:100%;
			color: black !important;
		}
		
		
	}
}
.navbar-nav {
	list-style: none;
	padding: 0;
	margin:0;
	width:100%;
	background-size:cover;
	.tournament-item{
		float: right;
		font-weight: bold;
		color: #fff;
		padding: 5px 20px 5px 20px;
		margin-right:0px!important;
		position: relative;
		cursor: pointer;
		
		&:after {
			content: "";
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;	
			z-index: -1;
			transform: skew(-30deg);
			transition: all .2s linear;
			color:white;
			background:#E4DC00;
			height:100%;
			color: black !important;
		}
		
		
	}
}
.navbar-nav {
	list-style: none;
	padding: 0;
	margin:0;
	width:100%;
	background-size:cover;
	.about-item{
		float: right;
		font-weight: bold;
		color: #fff;
		padding: 5px 20px 5px 20px;
		margin-right:0px!important;
		position: relative;
		cursor: pointer;
		
		&:after {
			content: "";
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;	
			z-index: -1;
			transform: skew(-30deg);
			transition: all .2s linear;
			color:white;
			background:#E4DC00;
			height:100%;
			color: black !important;
		}
		
		
	}
}
.navbar-nav {
	list-style: none;
	padding: 0;
	margin:0;
	width:100%;
	background-size:cover;
	.home-item {
		float: right;
		font-weight: bold;
		color: #fff;
		padding: 5px 20px 5px 20px;
		margin-right:0px!important;
		position: relative;
		cursor: pointer;
		
		&:after {
			content: "";
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;	
			z-index: -1;
			transform: skew(-30deg);
			transition: all .2s linear;
			color:white;
			background:#E4DC00;
			height:100%;
			color: black !important;
		}
		
		
	}
}
.navbar-nav {
	list-style: none;
	padding: 0;
	margin:0;
	width:100%;
	background-size:cover;
	.nav-item {
		float: right;
		font-weight: bold;
		color: #fff;
		padding: 5px 20px 5px 20px;
		margin-right:0px!important;
		position: relative;
		cursor: pointer;
		
		&:after {
			content: "";
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;	
			z-index: -1;
			transform: skew(-30deg);
			transition: all .2s linear;
			color:white;
		}
		
		&:hover:after {
			background:#E4DC00;
			z-index:-1;
			color: black !important;
			height:100%;
		}
	}
}
@media  screen and (max-width: 991px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			color: #fff;
			padding: 5px 20px 5px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
				background:rgb(0, 0, 0);
			}
			
			&:hover:after {
				background:rgb(0, 0, 0);
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}

}
@media  screen and (max-width:6000px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 1.2vw;
			color: #fff;
			padding: 30px 20px 30px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }

  @media  (min-width:4501px) and (max-width:5000px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 1.2vw;
			color: #fff;
			padding: 27px 20px 27px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  @media  (min-width:4000px) and (max-width:4500px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 1.3vw;
			color: #fff;
			padding: 18px 20px 18px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  
  @media  (min-width:2800px) and (max-width:3500px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 1.2vw;
			color: #fff;
			padding: 18px 20px 18px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  
@media  screen and (max-width:2800px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 25px;
			color: #fff;
			padding: 18px 20px 18px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
@media  screen and (max-width:2600px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 20px;
			color: #fff;
			padding: 15px 20px 15px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  @media  screen and (max-width:2500px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 20px;
			color: #fff;
			padding: 14px 20px 14px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  @media  screen and (max-width: 2200px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 20px;
			color: #fff;
			padding: 10px 20px 10px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  @media  screen and (max-width: 1900px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 20px;
			color: #fff;
			padding: 7px 20px 7px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  @media  screen and (max-width: 1800px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 20px;
			color: #fff;
			padding: 5px 20px 5px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  @media  screen and (max-width: 1700px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 20px;
			color: #fff;
			padding: 5px 20px 5px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  @media  screen and (max-width: 1600px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 19px;
			color: #fff;
			padding: 3.5px 20px 3.5px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  @media  screen and (max-width: 1500px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 12px;
			color: #fff;
			padding: 2px 20px 2px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  @media  screen and (max-width: 1400px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 17px;
			color: #fff;
			padding: 2px 20px 2px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  @media  screen and (max-width: 1300px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 15px;
			color: #fff;
			padding: 2.5px 20px 2.5px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  @media  screen and (max-width: 1200px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 12px;
			color: #fff;
			padding: 2.5px 20px 2.5px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  @media  screen and (max-width: 1100px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 12px;
			color: #fff;
			padding: 3px 20px 2px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:#E4DC00;
				z-index:-1;
				color: black !important;
				height:100%;
			}
		}
	}
  }
  @media  screen and (max-width: 991px) {
	.navbar-nav {
		list-style: none;
		padding: 0;
		margin:0;
		width:100%;
		background-size:cover;
		.nav-item {
			float: right;
			font-weight: bold;
			font-size: 12px;
			color: #fff;
			padding: 3px 20px 3px 20px;
			margin-right:0px!important;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;	
				z-index: -1;
				transform: skew(-30deg);
				transition: all .2s linear;
				color:white;
			}
			
			&:hover:after {
				background:black;
				z-index:-1;
				height:100%;
			}
		}
	}
  }
  a.nav-link.dropdown-toggle.show{
	color:white;
  }
  a.nav-link.dropdown-toggle{
	color:white;
  }