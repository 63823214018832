#recent_news{
    color:white;
    font-family: 'Cormorant', sans-serif;
    font-size: 2.3rem;
    letter-spacing: 2.8px;
    font-weight: 600;
    margin-top: -0.5rem;
}
.contents{
   color: #757575;
     /* font-size: 0.9rem;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
            line-clamp: 2; 
    -webkit-box-orient: vertical; */
    font-size: 0.9rem;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}
.content_heading{
    color: #EFEFEF;
    font-size: 1.1rem;
}
.chevron_left_right_buttons{
    display: none;
}
@media only screen and (max-width: 576px) {
    .content_heading {
        font-size: 0.855rem!important;
      
    }
    .contents{
        font-size: 0.655rem!important;
    }
    .news_dates{
        font-size: 0.6rem;
    }
  }