#tb_cricket_title {
  /* background-image: url("../images/privacy_pol_bac.png"); */
  font-family: 'Cormorant', sans-serif !important;
  background-size: 100%;
  word-spacing: -10px;
}
.bg_blue_img {
  z-index: -1;
}
#tb_cricket_title_bac_img{
  border-top: 4px solid #E4DC00;
  margin-top:-8px;
}
/* .success_heading{
  font-size:20px;
} */
.heading_bg_success{
 
    border-bottom: 37px solid #e4dc00 !important;
    border-right: 25px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 20px;
    padding-left: 20px;
  
}
.heading_bg_success span{
  font-size: 1.5rem;
  

}

@media screen and (min-width:497px) and (max-width:992px) {
  .success_heading{
    font-size:20px;
  }
  .heading_bg_success{
 
    border-bottom: 22px solid #e4dc00 !important;
    border-right: 25px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 22px;
    padding-left: 22px;
  
}
}
@media screen and (min-width:387px) and (max-width:496px) {
  .success_heading{
    font-size:0.8rem;
    border-bottom: 21px solid #E4DC00 !important;
    border-right: 25px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 20px;
    padding-left:20px;
  }
  .heading_bg_success{
 
    border-bottom: 12px solid #e4dc00 !important;
    border-right: 25px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 22px;
    padding-left: 22px;
  
}
.heading_bg_success span{
  font-size: 0.7rem;
  

}
}
@media screen and (min-width:200px) and (max-width:386px) {
  .success_heading{
    font-size:3vw;
    border-bottom: 12px solid #E4DC00 !important;
    border-right: 25px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 20px;
    padding-left:20px;
  }
  .heading_bg_success{
   
    border-bottom: 10px solid #e4dc00 !important;
    border-right: 21px solid transparent;
    height: 0;
    width: fit-content;
    padding-right: 16px;
    padding-left: 12px;
   
  
}
.heading_bg_success span{
  font-size: 0.7rem!important;
  

}
}
