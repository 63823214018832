.fa-arrow-up{
background-color: rgb(255, 255, 255);
padding: 1vh 0.7vw;
border-radius: 5px;
}
.explore h6,.more h6,.social h6{
    font-size: 16px;
}
.just_text{
    font-size: 14px;
}
.social img{
    width:16px
}
.social_logo_footer{
    width:80%;
}
.horizontal{
    width:66.6%;
    
}
.copyright p{
    font-size:14px;
    margin-left: -1%;
}

@media screen and (min-width:475px) and (max-width:556px){
    .just_text{
        font-size: 10px;
    }
    .explore h6,.more h6,.social h6{
        font-size: 10px;
    }
    .copyright p{
        font-size:10px;
        margin-left: -2%;
    }
} 

@media screen and (min-width:330px) and (max-width:475px) {
    .just_text{
        font-size: 0.4rem;
    }
    .explore h6,.more h6,.social h6{
        font-size: 12px;
    }
    .copyright p{
        font-size:8px;
        margin-left: -3.5%;
    }
} 

@media screen and (min-width:365px) and (max-width:388px) {
    .just_text{
        font-size: 0.3rem;
    }
    .explore h6,.more h6,.social h6{
        font-size: 12px;
    }
 
  } 
  @media screen and (max-width:365px) {
    .copyright p{
        font-size:6px;
        margin-left: -4%;
    }
  }
