/* This stylesheet generated by Transfonter (https://transfonter.org) on July 4, 2017 1:34 PM */

@font-face {
  font-family: "Agency Gothic CT";
  src: url("font/AgencyGothicCT-Medium.eot");
  src: local("Agency Gothic CT Medium"), local("AgencyGothicCT-Medium"),
    url("font/AgencyGothicCT-Medium.eot?#iefix") format("embedded-opentype"),
    url("font/AgencyGothicCT-Medium.woff") format("woff"),
    url("font/AgencyGothicCT-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Agency Gothic CT Condensed";
  src: url("font/AgencyGothicCT-Condensed.eot");
  src: local("Agency Gothic CT Condensed"), local("AgencyGothicCT-Condensed"),
    url("font/AgencyGothicCT-Condensed.eot?#iefix") format("embedded-opentype"),
    url("font/AgencyGothicCT-Condensed.woff") format("woff"),
    url("font/AgencyGothicCT-Condensed.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Agency Gothic CT";
  src: url("font/AgencyGothicCT-Light.eot");
  src: local("Agency Gothic CT Light"), local("AgencyGothicCT-Light"),
    url("font/AgencyGothicCT-Light.eot?#iefix") format("embedded-opentype"),
    url("font/AgencyGothicCT-Light.woff") format("woff"),
    url("font/AgencyGothicCT-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Agency Gothic CT Inline";
  src: url("font/AgencyGothicCT-Inline.eot");
  src: local("Agency Gothic CT Inline"), local("AgencyGothicCT-Inline"),
    url("font/AgencyGothicCT-Inline.eot?#iefix") format("embedded-opentype"),
    url("font/AgencyGothicCT-Inline.woff") format("woff"),
    url("font/AgencyGothicCT-Inline.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Agency Gothic CT";
  src: url("font/AgencyGothicCT-Bold.eot");
  src: local("Agency Gothic CT Bold"), local("AgencyGothicCT-Bold"),
    url("font/AgencyGothicCT-Bold.eot?#iefix") format("embedded-opentype"),
    url("font/AgencyGothicCT-Bold.woff") format("woff"),
    url("font/AgencyGothicCT-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Agency Gothic CT Open";
  src: url("font/AgencyGothicCT-Open.eot");
  src: local("Agency Gothic CT Open"), local("AgencyGothicCT-Open"),
    url("font/AgencyGothicCT-Open.eot?#iefix") format("embedded-opentype"),
    url("font/AgencyGothicCT-Open.woff") format("woff"),
    url("font/AgencyGothicCT-Open.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
