.contact_image {
   height: 100%;
   width: 100%
}

.map_text {
   margin-top: 40px;
   font-size: 2rem;
   font-weight: 400;
}

.mapouter {
   position: relative;
}

#gmap_canvas {
   height: 370px;
   width: 104%;
}

.address_icon {
   color: white;
   font-size: 2rem;
}

.email_icon {
   color: white;
   font-size: 2rem;
}

.form_header {
   font-size: 24px;
   font-weight: 700;
}

.form_content {
   margin-top: -5%;
}

.contact_form {
   margin-top: 4%;
   padding-left: 40px;
   padding-right: 40px;
   padding-bottom: 40px;
   border-radius: 2rem;
   border-style: groove;
   border-color: #f7f753;
   margin-bottom: 15%;
}

.form_input {
   padding-top: 10px;
   padding-bottom: 10px;
   border-radius: 0.5rem;
}

#message {
   padding-bottom: 4rem;
   margin-bottom: 5%;
}

#submit {
   background-color: #E10000;
}

.statue_of_liberty_image {
   height: 100%;
   width: 100%;
}

/* ###small devices## */
@media screen and (max-width:420px) {
   .map_text {
      margin-top: -30px;
      font-size: 1rem;
   }

   #gmap_canvas {
      height: 170px;
      width: 100%;
   }

   .address_icon {
      font-size: 1rem;
   }

   .email_icon {
      font-size: 1rem;
   }

   .address_email_head {
      font-size: 1rem;
   }

   .address_email_text {
      font-size: 1rem;
   }

   .form_header {
      font-size: 16px;
   }

   .contact_form {
      margin-top: 5%;
      margin-bottom: 15%;
   }

   #message {
      margin-bottom: 5%;
   }
}

@media screen and (min-width:768px) {
   .map_text {
      font-size: 1.5rem;
   }

   #gmap_canvas {
      height: 300px;
      width: 107.5%;
   }
}

@media screen and (min-width:992px) {
   #gmap_canvas {
      height: 300px;
      width: 105.5%;
   }

   .statue_of_liberty_image {
      width: 120%;
   }
}

@media screen and (min-width:1024px) {
   .statue_of_liberty_image {
      width: 132%;
   }
}

@media screen and (min-width:1100px) {
   .statue_of_liberty_image {
      width: 140%;
   }
}

@media screen and (min-width:1200px) {
   .map_text {
      font-size: 2rem;
   }

   #gmap_canvas {
      height: 340px;
      width: 104.5%;
   }

   .statue_of_liberty_image {
      width: 125%;
   }
}

@media screen and (min-width:1400px) {
   #gmap_canvas {
      height: 340px;
      width: 104%;
   }
}

@media screen and (min-width:1500px) {
   #gmap_canvas {
      height: 400px;
      width: 104%;
   }
   .statue_of_liberty_image {
          width: 145%;
   }
}