#recent_news{
    color:white;
    font-family: 'Agency Gothic CT';
    font-size: 2.3rem;
    letter-spacing: 2.8px;
    font-weight: 600;
}
.news_heading{
    font-family: "Montserrat";
    font-size: 3vw;
}
.news_contents{
    color: #757575;
    font-size: 0.9rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}
.content_heading{
    color: #EFEFEF;
    font-size: 1.1vw;
}
.highlights_content{
    margin-top: -8%;
    color: #f1f1f1;
    left: 8%;
   
}
.sponsor_name{
    margin-left:10%;
}

@media screen and (max-width: 670px) {
    .highlights_content {
      font-size: 0.8vw;
    }
  }