#terms_heading {
    font-family: 'Cormorant', sans-serif;
    padding-left: 10px;

}

.terms_content {
    border-top: 4px solid #E4DC00;
    margin-top: -16px;
}

.terms_con_posi {
    padding-top: 12%;
}

#text_gab_terms {
    margin-top: 30px;
}

@media screen and (min-width: 250px) and (max-width: 295px){
    #terms_heading{
        font-size: 0.7rem;
        
    }
}