.blog_gradient{
    position: absolute;
    top: 0;
}
.card_component{
   border-radius: 5px 5px !important;
}
.sports_details{
    margin-left: 26px;
}
.blog_centent_head{
    font-size: 1rem;
    font-weight: 700;
}
@media only screen and (max-width: 576px) {
    .card_component{
        margin-left: 17px;
        height: 85%!important;
        width: 90%!important;
    }
    .second_row{
        margin-top: -50px;
    }
}
