/* #slide_bars{
  position: absolute;
  top:2%;
  left:20%;
  height: 4%;
  width: 60%;
} */

/* .events { */
/* background-image: url('../images/events_cover.png'); */
/* background-size: 100% 100%; */
/* display: flex;
  flex-direction: column; */
/* height: 60vh;
  width: 100%; */
/* overflow: hidden; */
/* position: relative; */
/* overflow-x: auto; */
/* padding: 0% 3%; */
/* } */

/* .events_child{
  top: 19%;
  left: 4.5%;
  width: 54vw;
  height: 45vh;
  display: flex;
  align-items: center;
  overflow: hidden; */
/* overflow-x: auto; */
/* } */

/* .events_heading{
  font-family: "Agency Gothic CT";
  font-style: normal;
  word-spacing: 10px;
  letter-spacing: 2px;
} */
/* 
<img src={sig_event_bac} height="250px" width="100%" /> */

.wtcf_event_bac_cover {
  width: 100%;
  display: inline-block;
}
.events_heading {
  position: absolute;
  font-weight: 700;
  margin-top: 1.5%;
  padding-left: 17%;
  font-size: 2rem;
  z-index: 1;
  font-family: 'Cormorant', sans-serif;
  font-style: normal;
  word-spacing: 3px;
  letter-spacing: 1px; 
  font-weight: bolder;
}

.wtcf_event_topics {
  margin-top: 6%;
  position: absolute;
}

.carousel-indicators {
  visibility: hidden;
}

.event_para{
 text-align: justify;
}
.event_text_content_ {
  font-size: 0.9vw;
}
.wtcf_event_bac_cover{
  margin-left: 0px !important;
  width: 100%;
}
.wtcf_sign{
  font-size: 1.8vw;
}
.carousel_img1{
  width:145%;
}
.carousel_img2{
  width:145%;
}
@media screen and (max-width:488px) {
  .carousel_img1{
    width:170%;
  }
  .carousel_img2{
    width:170%;
  }
  
}
@media screen and (max-width:295px) {
  .carousel_img1{
    width:250%;
    margin-left:-30px;

  }
  .carousel_img2{
    width:250%;
    margin-left:-30px;
  }
  
}
@media screen and (max-width:6000px) {
  .carousel_img1{
    width:130%;
    margin-left:100px;

  }
  .carousel_img2{
    width:130%;
    margin-left:100px;
  }
  
}
@media screen and (max-width:992px) and (min-width:1200px){
  .others_events_sign{
    margin-top: 30px;
    margin-bottom: 10px;
  }

}
@media screen and (max-width:5000px) {
  .carousel_img1{
    width:130%;
    margin-left:50px;

  }
  .carousel_img2{
    width:130%;
    margin-left:30px;
  }
  
}
@media screen and (max-width:3000px) {
  .carousel_img1{
    width:130%;
    margin-left:30px;

  }
  .carousel_img2{
    width:130%;
    margin-left:30px;
  }
  
}
@media screen and (max-width:1300px) {
  .carousel_img1{
    width:130%;
    margin-left:0px;

  }
  .carousel_img2{
    width:130%;
    margin-left:0px;
  }
  
}
@media screen and (max-width:1000px) {
  .carousel_img1{
    width:150%;
    margin-left:0px;

  }
  .carousel_img2{
    width:150%;
    margin-left:0px;
  }
  
}
@media screen and (max-width:700px) {
  .carousel_img1{
    width:160%;
    margin-left:-20px;

  }
  .carousel_img2{
    width:160%;
    margin-left:-20px;
  }
  
}
@media screen and (max-width:500px) {
  .carousel_img1{
    width:170%;
    margin-left:-30px;

  }
  .carousel_img2{
    width:170%;
    margin-left:-30px;
  }
  
}
@media screen and (max-width:400px) {
  .carousel_img1{
    width:200%;
    margin-left:-30px;

  }
  .carousel_img2{
    width:190%;
    margin-left:-30px;
  }
  
}
@media screen and (max-width:300px) {
  .carousel_img1{
    width:270%;
    margin-right:20px;

  }
  .carousel_img2{
    width:270%;
    margin-right:20px;
  }
  
}
.others_events_sign{
  margin-top:30px;
}
@media screen and (max-width:991px) {
  .others_events_sign{
    display: none;
    
  }
  
}
@media screen and (max-width:1320px) { 
  .events_heading{
    font-size: 1.2rem;
  }
}
@media screen and (min-width:1320px) and (max-width:1390px) {
  .events_heading{
    font-size: 1.3rem;
  }
}
@media screen and (min-width:1390px) and (max-width:1525px) {
  .events_heading{
    font-size: 1.3rem;
  }
}
