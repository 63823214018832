/* .rules_title { 
  padding: 6px;
  background-color: #4CE50E;
  font-size: 1.2vw;
  font-weight: 800;
  text-align: center;
  font-family: Helvetica;
  color: #101010;
  } */
/* .number_point  p{
    line-height: 32px;
  } */
  .number_point b{
    color: #3D3D3D;
  }