 
.content_color{
  color: rgba(255, 255, 255, 0.753);
}
.history_pic2{
  position: absolute;
  left: 43%;
  margin-top: 30px;
  
}
.history_pic1{
  position: absolute;
  left: 35.5%;
  margin-top: -20px;
  border-top:2px solid #E10000;
}
/* @media screen and (max-width: 1092px) {
  .info_content_images {
    display: none;
  }
} */


.info_content_images {
  width: 100%;
}
.read-or-hide{
  color: red;
  cursor: pointer;
}
.heading_wtcf{
   color: white;
}
.wtcf_content3{
  text-align: justify;
}
#heading_history{
font-family: 'Cormorant', sans-serif;
font-style: normal;
word-spacing: 3px;
letter-spacing: 2px;
font-size: 1.6rem;

}
.why_wtcf_heading{
  font-family: 'Cormorant', sans-serif;
  font-style: normal;
  word-spacing: 1px;
  letter-spacing: 2px;
  font-size: 1.6rem;
}
.experience_heading{
  font-family: 'Cormorant', sans-serif;
  font-style: normal;
  word-spacing: 3px;
  letter-spacing: 1.5px;
  font-size: 1.6rem;
}
/* #font-size: 3vw; */
@media only screen and (max-width: 991px) {
	#wtcf_description {
		order: 2;
	}
	#info_image2 {
		order: 1;
	}

}
.info_3_img{
  border-top: 5px solid red;
}
.exp-heading{
  font-family: 'Cormorant', sans-serif;
  font-size: 1.6rem;
}