.sponsors{
  align-items: center;
  text-align: center;
}
.sponsor_title{
    display: flex;
    justify-content: center;
    
}
.sponsor_title p{ 
  background-color:#4CE50E;
  padding: 8px;
  font-weight: 800;
  color: black;
  width: 15rem;
  font-size: 1.3rem;
  font-family: Helvetica;
  text-align: center;
}
/* #flag {
    width: 100%;
    height: 60px;
    box-sizing: content-box;
    padding-top: 25px;
    position: relative;
    background: #5A189A;
    color: white;
    font-size: 11px;
    letter-spacing: 0.2em;
    text-align: center;
    text-transform: uppercase;
  }
  #flag:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 60px solid #ffffff;
    border-left: 700px solid transparent;
    border-right: 800px solid transparent;
  } */