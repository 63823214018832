.teaser_content {
  position: absolute;
  margin-top: 18%;
  margin-left: 8%;
  z-index: 1;
}

#myVideo {
  filter: brightness(40%);
  min-width: 100%;
  min-height: 20px;
  margin-bottom: -10px;
  /* width:80%; */
}

.make_enq_btn{
  background: #e10000;
  font: size 1vw !important;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 12px;
  padding-bottom: 12px;

  font-weight: 500;
  border: 1px solid #e10000;
  border-radius: 5px;
  letter-spacing: 1px;
}

.powering_heading {
  font-size: 3vw;
  font-family: 'Cormorant', sans-serif;
  font-style: normal;
}

.powering_para {
  font-size: 1.3vw;
  width: 90%;
}

.why_wtcf_title {
  color: #e4dc00;
}

.wtcf_content {
  text-align: justify;
}

.myModal {
  width: 325px;
  left: 38%;
  margin-top: 60px;
}

.modal-content {
  border-radius: 0px;
}

.modal_heading {
  position: absolute;
  top: 11%;
  left: 12%;
  color: white;
  font-size: 1rem;
  word-spacing: 5px;
  font-family: 'Cormorant', sans-serif;


}

.back_bg {
  width: 100% !important;
  border-radius: 0% !important;
  border: 1px solid #e0dbdb !important;
  resize: none;
  background-color: #F1F1F1;

}

.modal_image {
  background-size: cover;
  filter: brightness(25%);
}

.close_option {
  background: rgba(128, 128, 128, 0.404);
  border-radius: 50px;
  padding: 3px 9px;
  font-size: 0.9rem;
  font-weight: 800;
  position: absolute;
  right: 1.2%;
  top: 1.2%;
  color: white;
  cursor: pointer;
  z-index: 1;
}

.sumbit_btn {
  background-color: #E10000;
  border: 1px solid #E10000;
  padding: 7px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 1px;
  color: white !important;

}

.make_enq_btn:hover {
  background: #1937A1;
  border: 1px solid #1937A1;
}

.mission_heading {
  font-family: 'Cormorant', sans-serif;
  font-style: normal;
  font-size: 1.6rem;
  word-spacing: 3px;
  letter-spacing: 2px;
}

.executive_heading {
  font-family: 'Cormorant', sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  word-spacing: 3px;
  letter-spacing: 2px;
}

.accordion_heading {
  font-family: 'Cormorant', sans-serif;
  font-style: normal;
  word-spacing: 3px;
  font-size: 1.6rem!important;
  letter-spacing: 2px;
}
.accordion_heading_home {
  font-family: 'Cormorant', sans-serif;
  font-style: normal;
  word-spacing: 3px;
  font-size: 1.7rem!important;
  letter-spacing: 2px;
}

.view_more_btn>a {
  font-size: 12px;
}

.download_section_img {
  position: absolute;
  margin-top: 17%;
  padding-left: 10%;
}

.info_2_img,
.info_3_img {
  width: 100%;
}

.android_logo {
  width: 12vw;
  border:1px solid silver;
  border-radius:7px;
}

.ios_logo {
  width: 12vw;
  margin-left:5px;
  border:1px solid silver;
  border-radius:7px;
}

/* .qrcode_img{
  margin-bottom:8%;
  width:10vw;
  height:auto;
} */
@media screen and (max-width:6000px) and (min-width:2501px) {
  .execuive_names {
    font-family: 'Cormorant', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 2px;
    position: relative;
    bottom: 15%;
  }

  .executive_position {
    font-family: 'montserrat';
    font-size: 25px;
    position: relative;
    bottom: 15%;
  }
  .make_enq_btn{
    font-size: 2rem;
    border-radius: 5px;
  }
  .myModal {
    width: 100%;
    left: 2%;
    top: 15%;
  
  }
  .modal_heading{
    margin-left: 56px;
  }
}

@media screen and (max-width:2500px) and (min-width:1601px) {
  .execuive_names {
    font-family: 'Cormorant', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 2px;
    position: relative;
    bottom: 15%;
  }

  .executive_position {
    font-family: 'montserrat';
    font-size: 1vw;
    position: relative;
    bottom: 15%;
  }
  .make_enq_btn{
    font-size: 1.5rem;
    border-radius: 5px;
  }
  .myModal {
    width: 100%;
    left: 2%;
    top: 15%;
  
  }
  .modal_heading{
    margin-left: 56px;
  }
}

@media screen and (max-width:1600px) and (min-width:472px) {
  .execuive_names {
    font-family: 'Cormorant', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.3vw;
    letter-spacing: 2px;
    position: relative;
    bottom: 12%;
  }

  .executive_position {
    font-family: 'montserrat';
    font-size: 1vw;
    position: relative;
    bottom: 12%;
  }
}

@media screen and (max-width:471px) and (min-width:374px) {
  .execuive_names {
    font-family: 'Cormorant', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.3vw;
    letter-spacing: 2px;
    position: relative;
    bottom: 13%;
  }

  .executive_position {
    font-family: 'montserrat';
    font-size: 1vw;
    position: relative;
    bottom: 13%;
  }
}

@media screen and (max-width:373px) and (min-width:128px) {
  .execuive_names {
    font-family: 'Cormorant', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1vw;
    letter-spacing: 2px;
    position: relative;
    bottom: 15%;
  }

  .executive_position {
    font-family: 'montserrat';
    font-size: 0.8vw;
    position: relative;
    bottom: 18%;
  }
}

.mission_content {
  font-size: 0.855rem;
  letter-spacing: 0.5px;
}

/* To hide input number field arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.feedback {
  font-size: 0.7rem !important;
  text-align: left;
  font-weight: 700;
}

.executive {
  margin-bottom: -20px;

}


@media  screen and (max-width: 576px) {
  .make_enq_btn {
    padding: 5px 5px 5px 5px !important;
    font-size: 0.5rem !important;
    margin-bottom: 20px;
    border-radius: 2px;
  }

  .powering_heading {
    margin-top: 29px;
    font-size: 0.9rem !important;
  }

  .mission_content {
    font-size: 0.6rem;
    width: 100%;
  }

  .idea_img {
    width: 49%;
    position: relative;
    right: 34%;
  }

  .mission_heading {
    font-size: 1.2rem;
    letter-spacing: 1px;

  }

  .jit_img {
    width: 80%;
    height: 70%;
  }

  .neil_img {
    width: 80%;
    height: 70%;
  }

  .executive_heading {
    font-size: 1.2rem;
  }

  .executive {
    margin-bottom: -20px;

  }

  .execuive_names {
    font-size: 0.5rem;
  }

  .executive_position {
    font-size: 0.4rem;
  }

  .myModal {
    width: 310px;
    margin-left: -7rem;
  }

  .modal-open {
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 0 !important;
  }

}

@media screen and (max-width:414px) {
  .powering_heading {
    font-size: 5vw;
    /* letter-spacing: 1px; */
    /* word-spacing: 3px; */
  }
}

@media screen and (max-width:1600px) and (min-width:1000px) {
  .jit_img {
    width: 70%;
    height: 80%;
  }

  .neil_img {
    width: 70%;
    height: 80%;
  }
}

@media screen and (max-width:1000px) and (min-width:600px) {
  .jit_img {
    width: 70%;
    height: 70%;
  }

  .neil_img {
    width: 70%;
    height: 70%;
  }
}

@media screen and (max-width:599px) and (min-width:577px) {
  .jit_img {
    width: 70%;
    height: 70%;
  }

  .neil_img {
    width: 70%;
    height: 70%;
  }
}

@media screen and (max-width:239px) and (min-width:128px) {
  .jit_img {
    width: 150%;
    height: 90%;
  }

  .neil_img {
    width: 150%;
    height: 90%;
  }
  .powering_heading{
    margin-top: 10px;
    font-size: 0.7rem!important;
  }
  .make_enq_btn{
    padding: 2px 2px 2px 2px !important;
    font-size: 0.4rem !important;
    margin-bottom: -100px;
    border-radius: 5px;;
  }
}

@media screen and (max-width:992px) {
  .teaser_content {
    position: absolute;
    margin-top: 13%;
    margin-left: 8%;
    z-index: 1;
  }
}

@media screen and (max-width:992px) {
  .android_logo {
    width: 10vw;
    margin-right: 10px;
    margin-bottom: 3%;
    border:1px solid silver;
  border-radius:5px;
  }

  .ios_logo {
    width: 10vw;
    margin-bottom: 3%;
    border:1px solid silver;
  border-radius:5px;
  }
}
.myModal {
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.myModal::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.myModal {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* To hide scrollbar from firefox */
html {
  scrollbar-width: none;
}
