.discover_img{
    /* z-index: -1;  */
    background-image: url("../images/discover_img.png");
    padding: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 50%; */
}
.discover_text {
    line-height: 40px; 
    color:#FFFFFF ;
    font-weight: 700;
    font-size: 3vw;
    font-family: Helvetica;
}
.question th{
    font-family:Helvetica;
}
b{
    font-size: 22px;
    color: #3B0C67;
}
.point_a{
    color: #FFFFFF;
}
.tennis_text {
   font-family: Helvetica;
    color:#FFD93D ;
    font-weight: 900;
    font-size: 5vw;
    margin-top: -4%;    
    
}
.ball{
    margin-top: -4%;
}
.cricket{
    margin-top: -4%;
}
.cricketers {
    font-size: 1.5rem;
    color: #FFFFFF;
   font-family: Montserrat;

}
.detail_info{
    background-image: linear-gradient(to right,#240046 , #7B2CBF) !important;
}
.details{
    padding: 40px;

    
}
.details h2{ 
    /* margin:75px 100px; */
  background-color:#4CE50E;
  padding: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  color: black;
  letter-spacing: 1px;
  font-size: 1.5rem;
  font-family: Helvetica;
}
.notes{
    background-color: #FFFFFF;
    color: #212121;
    padding: 20px;
    
}
.question2{
    float: right;
    

}
@media screen and (max-width:991px) {
 .question2{
    float: left;
    
 }
 
}
@media screen and (min-width:400px) {
    .question2{
       
       width: 250px;
       
    }
    
   }