
.img_text{
    background-size: 100% 100%;
    padding: 20px ;
    background-position: center;
    background-image: url("../images/rewards_card.png");
    color: white;
    display: flex;
    flex-direction: column;
    height:100%;
    width: 100%;
}
.numbers{
    background-color: #FFE150;
    width: 2.5rem;
    height: 2.5rem;
    padding-left: 2%;
    border-radius: 10px;
    font-weight: 600;
    color: black;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    font-family: Montserrat;
}
.two_numbers{
    background-color: #FFE150;
    width: 2.5rem;
    height: 2.5rem;
    padding-left: 1%;
    border-radius: 10px;
    font-weight: 800;
    color: black;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    font-family: Montserrat;
}
.winners{
    color: white;
    font-size: 0.9rem;
    font-weight: 600;
}
.prize_amt{
    font-weight:800;
    font-size:35px;
    letter-spacing: 2px;
    color: white;
    font-family: Helvetica;
}
.trophy_text{
    margin-top: -8px;
    margin-bottom: -12px;
    font-size: 1rem;
    font-weight: 600;
}
/* @media screen and  (max-width:768px) {
    .rewards_img{
        margin-top: -4%;
    }
} */